<template>
  <div class="subPage">
    <div class="subPageTitle">
      <div class="subPageTitleText">
        <p>我的仓单</p>
      </div>
    </div>
    <div class="searchList">
      <el-row :gutter="10">
        <!-- <el-col :span="4">
          <el-input v-model.number="searchForm.holdId" placeholder="请输入持仓ID"></el-input>
        </el-col> -->
        <el-col :span="4">
          <el-select v-model="searchForm.storageId" class="width-100" placeholder="请选择仓库">
            <el-option
              v-for="item in storageIdOptions"
              :key="item.storageId"
              :label="item.storageName"
              :value="item.storageId"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-cascader
            v-model="selectCategoryOptions"
            class="width-100"
            :options="categoryOptions"
            :props="categoryProps"
            placeholder="请选择商品分类"
            clearable
          ></el-cascader>
        </el-col>
        <!-- <el-col :span="4">
          <el-select v-model="searchForm.status" placeholder="请选择仓单状态">
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col> -->
        <el-col :span="4">
          <el-input v-model="searchForm.holdCode" placeholder="请输入仓单编码"></el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="danger" @click="getDatas()">搜索</el-button>
          <el-button @click="resetSearch()">重置</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="tableList">
      <el-table :data="tableDatas">
        <!-- <el-table-column label="序号" type="index" show-overflow-tooltip width="50">
        </el-table-column>-->
        <el-table-column prop="warehouseReceiptCode" label="仓单编码"></el-table-column>
        <!-- <el-table-column prop="holdCode" label="入库单编码"></el-table-column> -->
        <el-table-column prop="commodityName" label="商品">
          <template slot-scope="scope">
            <span>{{ scope.row.commodityName ? scope.row.commodityName :'---' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="storageName" label="仓库"></el-table-column>
        <el-table-column prop="specificationModel" label="商品属性">
          <template slot-scope="scope">
            <span>{{ scope.row.specificationModel | firstAttributeTransformate }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="applyHoldStatus" label="持仓类型">
          <template slot-scope="scope">
            <span>{{ scope.row.applyHoldStatus| holdTypeFilter }}</span>
          </template>
        </el-table-column> -->
        <!-- <el-table-column prop="categoryName" label="商品分类"></el-table-column> -->

        <!-- <el-table-column prop="quantity" label="数量">
          <template slot-scope="scope">
            <span>{{ scope.row.quantity?scope.row.quantity:'--'}}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="weight" label="重量">
          <template slot-scope="scope">
            <span>{{ scope.row.weight }}{{ scope.row.unit }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="warehouseQuantity" label="数量">
          <template slot-scope="scope">
            <span>{{ scope.row.warehouseQuantity }}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="createTime" label="可交收重量">
          <template slot-scope="scope">
            <span>
              {{ Number(scope.row.weight) - Number(scope.row.soldWeight) - Number(scope.row.frozenWeight) - Number(scope.row.deliveryWeight) }}
              {{ scope.row.unit }}
            </span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="holdWeight" label="可交收重量">
          <template slot-scope="scope">
            <span v-if=" scope.row.holdType=='Y'">0 {{ scope.row.unit }}</span>
            <span v-else>{{ scope.row.canWeight }}{{ scope.row.unit }}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="applyHoldStatus" label="创建时间">
          <template slot-scope="scope">
            <span>{{ scope.row.createTime | DateFormateDay }}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <div class="operateButtons">
              <el-button
                v-if="(Number(scope.row.weight) - Number(scope.row.soldWeight) - Number(scope.row.frozenWeight) - Number(scope.row.deliveryWeight)) > 0"
                style="width: 88px!important"
                type="primary"
                size="mini"
                @click="checkout(scope.row)"
              >提货</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        :current-page="current"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPage"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
    <!-- 查看 -->
    <viewDialog ref="viewDialog" />
    <!-- 持仓明细 -->
    <!-- <holdDetailDialog ref="viewHoldDialog" /> -->
    <!-- 提货 -->
    <deliveryDialog ref="deliveryDialog" :source="currentSource" :bs-flag="currentDerict" />
    <!-- 现货挂卖 -->
    <!-- <spotSellDialog ref="spotSellDialog" /> -->
    <!-- 预售挂卖 -->
    <!-- <preSellResourceDialog ref="preSellResourceDialog" /> -->
    <!-- 竞价挂卖 -->
    <!-- <biddingSellDialog ref="biddingSellDialog" /> -->

    <!-- 补入库仓单 -->
    <!-- <supplementaryDialog ref="supplementaryDialog" @reLoad="getDatas()" /> -->
    <!-- 增发仓单 -->
    <!-- <addHoldWeight ref="addHoldWeight" /> -->
    <!-- 融资 -->
    <!-- <addPledgeDialog ref="addPledgeDialog" /> -->
  </div>
</template>
<script>
/* global  protocolNJP http util protocolNJP*/
import { mapGetters } from 'vuex'
import deliveryDialog from './deliveryDialog'
import viewDialog from './viewDialog'
// import holdDetailDialog from './holdDetail'
// import spotSellDialog from './spotResource/addDialog'
// import preSellResourceDialog from './preSellResource/addDialog'
// import biddingSellDialog from './biddingResource/addBiddingResource'
// import supplementaryDialog from '../../resourceMgt/warehousingApply/supplementaryDialog'
// import addHoldWeight from './addHoldWeight'
// import addPledgeDialog from './addPledge'
export default {
  data() {
    return {
      currentDerict: 'S',
      current: 1,
      pageSize: 10,
      totalPage: null,
      tableDatas: [],
      isShowFinanceOrPledge: window.g.isShowFinanceOrPledge,
      searchForm: {
        storageId: null,
        status: null,
        holdId: null,
        holdCode: null
      },
      dialogVisible: false,
      delivery: {
        warehousId: null,
        type: 0
      },
      source: 0,
      right: {
        addSpotResource: false,
        addPreResource: false,
        delivery: false,
        fillPresale: false,
        addHoldWeight: false,
        sureFillHold: false
      },
      currentSource: 0,
      selectCategoryOptions: [],
      categoryOptions: [],
      statusOptions: [
        // applyHoldStatus  申请仓单状态W：待入库Y：已入库T：质检通过F：质检不通过D：已提走U：已撤销
        { value: 'W', label: '待入库' },
        { value: 'Y', label: '已入库' },
        { value: 'T', label: '质检通过' },
        { value: 'F', label: '质检不通过' },
        { value: 'D', label: '已提走' },
        { value: 'U', label: '已撤销' }
        // { value: "B", label: "部分回购" },
        // { value: "A", label: "全部回购" },
      ],
      storageIdOptions: [],
      categoryProps: {
        label: 'className',
        value: 'classId',
        checkStrictly: false
      }
    }
  },
  computed: {
    ...mapGetters(['sessionInfoGetter'])
  },
  components: {
    deliveryDialog,
    // holdDetailDialog,
    // spotSellDialog,
    // preSellResourceDialog,
    // biddingSellDialog,
    viewDialog
    // supplementaryDialog,
    // addHoldWeight,
    // addPledgeDialog
  },
  mounted() {
    this.getDatas()
    // this.getRights()
    this.getCommodityCategory() // 获取商品分类
    this.getStorageIdOptions() // 获取仓库
  },

  methods: {
    getRights() {
      this.loadRight().then((loadResult) => {
        if (loadResult == 'loadRightSuccess') {
          this.right.addSpotResource = this.isHasRight(
            'tc-trade-sellHangStockOrder'
          )
          this.right.addPreResource = this.isHasRight(
            'tc-trade-hangSellPresaleResources'
          )
          this.right.delivery = this.isHasRight('tc-trade-deliveryWarehous')
          this.right.fillPresale = this.isHasRight(
            'tc-trade-fillApplyWarehous'
          )
          // 补入库完成确认权限
          this.right.sureFillHold = this.isHasRight(
            'tc-trade-fillHold'
          )
          this.right.addHoldWeight = this.isHasRight('tc-trade-addHoldWeight')
        }
      })
    },
    resetSearch() {
      this.searchForm = {
        storageId: null,
        status: null,
        holdId: null,
        holdCode: null
      }
      this.selectCategoryOptions = []
      this.getDatas()
    },
    // 获取商品分类
    getCommodityCategory() {
      http.getRes(protocolNJP.param_getGoodsClassTree).then((response) => {
        const { code, message, value } = response.data
        if (code == 0) {
          const categoryArr = value
          this.categoryOptions = this.clearChildren(categoryArr)
          console.log(this.categoryOptions, '789789')
        } else {
          this.categoryOptions = []
          this.$EL_MESSAGE(message)
        }
      })
    },
    clearChildren(Arr) {
      Arr.forEach((element) => {
        if (element && element.children) {
          if (element.children.length == 0) {
            delete element['children']
          } else {
            this.clearChildren(element.children)
          }
        }
      })
      return Arr
    },
    // 查询仓库
    getStorageIdOptions() {
      http.postFront(protocolNJP.param_getStorage).then((response) => {
        const { code, message, value } = response.data
        if (code == 0) {
          this.storageIdOptions = value
        } else {
          this.$EL_MESSAGE(message)
        }
      })
    },
    showDetails(row) {
      this.$refs.viewDialog.showDialog(row)
    },
    showHoldDetails(row) {
      this.$refs.viewHoldDialog.showDialog(row)
    },
    appalyFinancing(row) {
      const tempData = JSON.parse(JSON.stringify(row))
      this.$refs.addPledgeDialog.showDialog(tempData.holdId)
    },
    sureFillHold(id) {
      this.$confirm('温馨提示：确认完成补入库后将不能再补入库，如补入库重量与订单重量比例不达标，将面临违约，会产生违约金，请仔细确认！', '您确认补入库完成吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'ruku',
        type: 'warning'
      })
        .then(() => {
          protocolNJP.param_fillHold.param.holdId = id
          http.postFront(protocolNJP.param_fillHold).then((response) => {
            const { code, message } = response.data
            if (code == 0) {
              this.$EL_MESSAGE(message)
              this.getDatas()
            } else {
              this.$EL_MESSAGE(message)
            }
          })
        })
        .catch(() => {})
    },
    cancleApplay(row) {
      this.$confirm('请确认是否撤销该持仓申请吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          protocolNJP.param_auditHold.param.auditHoldStatus = 'U'
          protocolNJP.param_auditHold.param.holdId = row.holdId
          http.postFront(protocolNJP.param_auditHold).then((response) => {
            const { code, message } = response.data
            if (code == 0) {
              this.$EL_MESSAGE(message)
              this.getDatas()
            } else {
              this.$EL_MESSAGE(message)
            }
          })
        })
        .catch(() => {})
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getDatas()
    },
    handleCurrentChange(val) {
      this.current = val
      this.getDatas()
    },
    getDatas() {
      // applyHoldStatus  申请仓单状态W：待入库Y：已入库T：质检通过F：质检不通过D：已提走U：已撤销
      // console.log(this.searchForm, '123')
      protocolNJP.param_findWarehouseOrders.param.page = this.current - 1
      // protocolNJP.param_findWarehouseOrders.param.auditHoldStatus = 'T'
      protocolNJP.param_findWarehouseOrders.param.size = this.pageSize
      protocolNJP.param_findWarehouseOrders.param.storageId = this.searchForm.storageId // 仓库名称
      // protocolNJP.param_findWarehouseOrders.param.className = this.selectCategoryOptions[this.selectCategoryOptions.length - 1] // 商品分类
      protocolNJP.param_findWarehouseOrders.param.sort = [
        { property: 'applyWarehouseId', direction: 'DESC' }
      ]
      protocolNJP.param_findWarehouseOrders.param.warehouseReceiptCode = this.searchForm.holdCode
      //  protocolNJP.param_queryWarehousSellerBuyer.param.type = 3; //展示买入卖出仓单  1展示卖出0展示买入
      // protocolNJP.param_findWarehouseOrders.param.holdType = null // 持仓类型X：现货持仓Y：预售持仓B：预售补入库持仓
      // protocolNJP.param_findWarehouseOrders.param.className = null; //持仓类型X：现货持仓Y：预售持仓B：预售补入库持仓
      // protocolNJP.param_findWarehouseOrders.param.holdStatus = null; //持仓状态T：已入库F：未入库
      // protocolNJP.param_findWarehouseOrders.param.applyHoldStatus = this.searchForm.status // 持仓状态T：已入库F：未入库
      protocolNJP.param_findWarehouseOrders.param.categoryId = this.selectCategoryOptions.length > 0 ? this.selectCategoryOptions[this.selectCategoryOptions.length - 1] : null
      // protocolNJP.param_findWarehouseOrders.param.specificationsId = null
      // protocolNJP.param_findWarehouseOrders.param.specificationsName = null // 规格名称 例{颜色：红}
      // protocolNJP.param_findWarehouseOrders.param.sellStatus = null // 规格名称 例{颜色：红}
      http.postFront(protocolNJP.param_findWarehouseOrders).then((response) => {
        const { code, message, value } = response.data
        if (code == 0) {
          for (let i = 0; i < value.content.length; i++) {
            value.content[i].canWeight = util.floatSub(
              value.content[i].holdWeight,
              value.content[i].frozenHoldWeight
            )
            if (value.content[i].holdCode == null || value.content[i].holdCode == '') {
              value.content[i].holdCode = '---'
            }
          }
          this.tableDatas = value.content
          this.totalPage = value.totalElements
        } else {
          this.$EL_MESSAGE(message)
        }
      })
    },
    checkout(row) {
      // if(row.holdType=='X'||row.holdType=='Y'){
      //   this.currentSource = 0
      // }else{
      //   this.currentSource = 1
      // }
      // if(row.buyFirmId==0){
      this.currentDerict = 'S'
      this.$refs.deliveryDialog.showDialog(row)
      // }else{
      //   this.currentDerict = 'B'
      //   this.$refs.deliveryDialog.showDialog(row);
      // }
    },
    addHoldWeight(row) {
      this.$refs.addHoldWeight.showDialog(row)
    },
    goDetail(row) {
      const tempData = JSON.parse(JSON.stringify(row))
      this.$refs.supplementaryDialog.showDialog(tempData)
    },
    goSpotSell(row) {
      const tempData = JSON.parse(JSON.stringify(row))
      this.$refs.spotSellDialog.showDialog(tempData.holdId)
    },
    goPreSell(row) {
      const tempData = JSON.parse(JSON.stringify(row))
      this.$refs.preSellResourceDialog.showDialog(tempData.holdId)
    },
    goBiddingSell(row) {
      const tempData = JSON.parse(JSON.stringify(row))
      this.$refs.biddingSellDialog.showDialog(
        tempData.holdId,
        tempData.holdType
      )
    }
  }
}
</script>
<style scoped lang="scss">
.subPage {
  padding: 0 15px;
}
.subPageTitle {
  overflow: hidden;
}
.subPageTitle .subPageTitleText {
  float: left;
  line-height: 40px;
}
.subPageTitle .subPageTitleText p {
  font-size: 16px;
}
.tableList {
  margin-top: 20px;
}
.tableList {
  margin: 10px 0;
  /deep/ .el-table thead th {
    background-color: rgba(5, 64, 106, 1);
    color: #fff;
  }
  .allSelectBox {
    line-height: 30px;
    margin-left: 20px;
  }
}
.deliveryContent {
  line-height: 30px;
}
.operateButtons button {
  display: block;
  margin: 5px auto;
}
.subPage .pagination {
  margin-top: 15px;
}
</style>
