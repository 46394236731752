<template>
  <el-dialog
    v-dialogDrag
    title="查看持仓详情"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="900px"
  >
    <!-- <div class="auditCon">
      <p class="auditNote">审核意见：{{ form.auditNote }}</p>
    </div>-->
    <div class="viewDetails">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">商品分类：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.commodityName }}</p>
            </el-col>
          </el-row>
        </el-col>
        <!-- <el-col v-for="(item,index) of specificationModel" :key="index" :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">商品属性：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ item }}</p>
            </el-col>
          </el-row>
        </el-col> -->
        <el-col v-for="(item,index) in nameArrays" :key="index" :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">{{ item.name }}：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ item.value }}</p>
            </el-col>
          </el-row>
        </el-col>
        <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">生产厂家：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.orgName ? form.orgName : '---' }}</p>
            </el-col>
          </el-row>
        </el-col> -->
        <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">产地：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.placeOrigin }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">品牌：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.brand }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col v-if="form.quantity" :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">数量：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.quantity }}</p>
            </el-col>
          </el-row>
        </el-col> -->
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">持仓重量：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.warehouseWeight }}{{ form.unit }}</p>
            </el-col>
          </el-row>
        </el-col>
        <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">申请持仓重量：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.putHoldWeight }}{{ form.unit }}</p>
            </el-col>
          </el-row>
        </el-col> -->
        <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">实际入库重量：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.realHoldWeight }}{{ form.unit }}</p>
            </el-col>
          </el-row>
        </el-col> -->
        <!-- <el-col v-if="form.holdStatus=='B'" :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">待分配重量：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.surplusHoldWeight }}{{ form.unit }}</p>
            </el-col>
          </el-row>
        </el-col> -->
        <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">已交收重量：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.deliveryWeight }}{{form.unit}}</p>
            </el-col>
          </el-row>
        </el-col> -->
        <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">已售重量：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.sellHoldWeight }}{{ form.unit }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">冻结重量：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.frozenHoldWeight }}{{ form.unit }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">磅差：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.poundsPoor }}{{ form.unit }}</p>
            </el-col>
          </el-row>
        </el-col> -->
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">仓库名称：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.storageName }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">企业名称：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.orgName ? form.orgName : '---' }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">联系人：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.userName }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">联系方式：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.userPhone }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">状态：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.applyHoldStatus | applyHoldStatus }}</p>
            </el-col>
          </el-row>
        </el-col>
        <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">类型：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.holdType | holdTypeFilter }}</p>
            </el-col>
          </el-row>
        </el-col> -->
        <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">生产日期：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.tradeDay | dataFormateYear }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">实际入库时间：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.fillPutWarehousTime | DateFormate }}</p>
            </el-col>
          </el-row>
        </el-col> -->
        <!-- <el-col :span="24">
          <el-row :gutter="20">
            <el-col :span="4">
              <p class="title">图片：</p>
            </el-col>
            <el-col :span="8">
              <img v-if="form.image !== '--'" class="proPictureImg" :src="form.image">
              <p v-else>--</p>
            </el-col>
          </el-row>
        </el-col> -->
      </el-row>

      <!-- <el-row :gutter="20">
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">生产厂家：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.manufacturer }}</p>
            </el-col>
          </el-row>
        </el-col>
      </el-row>-->
      <!-- <el-row :gutter="20">

      </el-row>
      <el-row :gutter="20">

      </el-row>
      <el-row :gutter="20">

      </el-row>-->
    </div>
  </el-dialog>
</template>
<script>
/* global  protocolFwd http */
export default {
  data() {
    return {
      dialogVisible: false,
      specificationModel: {},
      nameArrays: [],
      form: {
        // commodityId: null,// 商品id
        // image: null,// 商品图片
        // poundsPoor: null,// 磅差
        // putHoldWeight: null, //重量
        // quantity: null,//数量
        // specificationsName: [],//规格名称{颜色：红色}json形式
        // unit: ""// 重量单位
      }
    }
  },
  methods: {
    showDialog(row) {
      this.dialogVisible = true
      this.form = row
      this.nameArrays = this.NameValueTransformate(row.specificationModel)
    },
    NameValueTransformate(obj) {
      if (obj != null) {
        const nameArray = []
        const newObj = JSON.parse(obj)
        const newObjKey = Object.keys(newObj)
        const newObjValue = Object.values(newObj)
        if (newObjKey.length != 0) {
          for (let i = 0; i < newObjKey.length; i++) {
            const obj1 = {}
            obj1.name = newObjKey[i]
            obj1.value = newObjValue[i]
            nameArray.push(obj1)
          }
          return nameArray
        }
      }
    },
    getDetails(id) {
      protocolFwd.param_queryHold.param = {
        page: 0,
        size: 1,
        holdId: id,
        className: null,
        auditHoldStatus: null, // 审核状态W：待审核T：审核通过F：审核不通过
        commodityId: null,
        holdStatus: null, // 持仓状态T：已入库F：未入库
        holdType: null, // 持仓类型X：现货持仓Y：预售持仓B：预售补入库持仓
        sellStatus: null,
        specificationsId: null, // 规格id 例1-1
        specificationsName: null // 规格名称 例{颜色：红}
      }
      http.postFront(protocolFwd.param_queryHold).then(response => {
        const { code, message, value } = response.data
        if (code == 0) {
          if (value) {
            value.content[0].specificationsName = this.attributeTrans(
              value.content[0].specificationsName
            )
            this.form = value.content[0]
            for (const i in this.form) {
              if (this.form[i] === null) {
                this.form[i] = '--'
              }
            }
          }
        } else {
          this.$EL_MESSAGE(message)
        }
      })
    },
    handleClose() {
      this.dialogVisible = false
    }
  }
}
</script>
<style lang="scss" scoped>
.viewDetails {
  line-height: 40px;
}
.viewDetails .title {
  text-align: right;
}
.proPictureImg {
  max-width: 80%;
  width: 120px;
  height: 120px;
  margin-top: 15px;
}
.auditCon {
  position: absolute;
  left: 20%;
  top: 20px;
}
</style>
