import { render, staticRenderFns } from "./myPosition.vue?vue&type=template&id=21f4d4ff&scoped=true"
import script from "./myPosition.vue?vue&type=script&lang=js"
export * from "./myPosition.vue?vue&type=script&lang=js"
import style0 from "./myPosition.vue?vue&type=style&index=0&id=21f4d4ff&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21f4d4ff",
  null
  
)

export default component.exports